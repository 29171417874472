<template>  
  <svg :width="iconSize" :height="iconSize" viewBox="0 0 18.92 16.524">
    <g id="Group_1" data-name="Group 1" transform="translate(-53.332 -221.54)">
      <path id="Icon_open-media-play" data-name="Icon open-media-play" d="M0,0V7.945L7.945,3.972Z" transform="translate(58.819 226.567)" :fill="iconColor"/>
      <path id="Icon_feather-folder" data-name="Icon feather-folder" d="M19.917,16.763A1.716,1.716,0,0,1,18.2,18.479L3.633,18.322a1.716,1.716,0,0,1-1.716-1.716V4.591A1.716,1.716,0,0,1,3.633,2.875H7.924L9.64,5.45l8.56.157a1.716,1.716,0,0,1,1.716,1.716Z" transform="translate(51.875 219.125)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0.92"/>
    </g>
  </svg>
</template>

<script>
  export default {
    props: {
      iconSize:Number,
      iconColor:String,
    }
  }
</script>