import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index.js';

// Timer
// import Timer from '../views/Timer.vue'
const Timer = () => import(/* webpackChunkName: "timer" */'@/views/Timer.vue')

// Broadcast
// import Broadcast from '../views/Broadcast.vue'
const Broadcast = () => import(/* webpackChunkName: "Broadcast" */'@/views/Broadcast.vue')

// Iframes
// import LiveEvent from '../views/LiveEvent.vue'
const LiveEvent = () => import(/* webpackChunkName: "LiveEventOld" */'@/views/LiveEvent.vue')
// import OnDemand from '../views/OnDemand.vue'

// Auth
const Login = () => import(/* webpackChunkName: "login" */'@/views/Auth/Login.vue')
const ForgotPassword = () => import(/* webpackChunkName: "forgotPass" */'@/views/Auth/ForgotPassword.vue')
const ResetPassword = () => import(/* webpackChunkName: "resetPass" */'@/views/Auth/ResetPassword.vue')

// Home
// import Home from '../views/Home.vue'
const Home = () => import(/* webpackChunkName: "home" */'@/views/Home.vue')

// Events
const Events = () => import(/* webpackChunkName: "events" */'@/views/Events/Events.vue')
const AddEvent = () => import(/* webpackChunkName: "addEvent" */'@/views/Events/AddEvent.vue')
const EditEvent = () => import(/* webpackChunkName: "editEvent" */'@/views/Events/EditEvent.vue')

// Sermon Notes
const Notes = () => import(/* webpackChunkName: "notes" */'@/views/SermonNotes/Notes.vue')
const EditNotes = () => import(/* webpackChunkName: "EditNotes" */'@/views/SermonNotes/SuperEditNotes.vue')

// 24/7 Schedule
const Schedule = () => import(/* webpackChunkName: "Schedule" */'@/views/Layouts/Schedule.vue')


// Podcasts
const Podcasts = () => import(/* webpackChunkName: "Podcasts" */'@/views/Podcasts/Podcasts.vue')
const AddPodcast = () => import(/* webpackChunkName: "AddPodcast" */'@/views/Podcasts/AddPodcast.vue')
const EditPodcast = () => import(/* webpackChunkName: "EditPodcast" */'@/views/Podcasts/EditPodcast.vue')

// Ad Types
// import AdvertisementTypes from '../views/Ads/AdvertisementTypes.vue'
const AdvertisementTypes = () => import(/* webpackChunkName: "AdvertisementTypes" */'@/views/Ads/AdvertisementTypes.vue')
// import AddAdvertisementType from '../views/Ads/AddAdvertisementType.vue'
const AddAdvertisementType = () => import(/* webpackChunkName: "AddAdvertisementType" */'@/views/Ads/AddAdvertisementType.vue')
// import EditAdvertisementType from '../views/Ads/EditAdvertisementType.vue'
const EditAdvertisementType = () => import(/* webpackChunkName: "EditAdvertisementType" */'@/views/Ads/EditAdvertisementType.vue')

// Ads
const Advertisement = () => import(/* webpackChunkName: "Advertisement" */'@/views/Ads/Advertisement.vue')
// import AddAdvertisement from '../views/Ads/AddAdvertisement.vue'
const AddAdvertisement = () => import(/* webpackChunkName: "AddAdvertisement" */'@/views/Ads/AddAdvertisement.vue')
// import GraphicAds from '../views/Ads/GraphicAds.vue'
const GraphicAds = () => import(/* webpackChunkName: "GraphicAds" */'@/views/Ads/GraphicAds.vue')
// import VideoAds from '../views/Ads/VideoAds.vue'
const VideoAds = () => import(/* webpackChunkName: "VideoAds" */'@/views/Ads/VideoAds.vue')
// import EditAdvertisement from '../views/Ads/EditAdvertisement.vue'
const EditAdvertisement = () => import(/* webpackChunkName: "EditAdvertisement" */'@/views/Ads/EditAdvertisement.vue')
// import AdvertisementStatistics from '../views/Ads/AdvertisementStatistics.vue'
const AdvertisementStatistics = () => import(/* webpackChunkName: "AdvertisementStatistics" */'@/views/Ads/AdvertisementStatistics.vue')
// import AddAd from '../views/Ads/AddAd.vue'
const AddAd = () => import(/* webpackChunkName: "addAd" */'@/views/Ads/AddAd.vue')

// Settings
const OrganizationDetails = () => import(/* webpackChunkName: "ogrDetails" */'@/views/Settings/OrganizationDetails.vue')
const NewOrganization = () => import(/* newOrg: "advertisementStatistics" */'@/views/Settings/NewOrganization.vue')
const Links = () => import(/* webpackChunkName: "links" */'@/views/Settings/Links.vue')
const Seo = () => import(/* webpackChunkName: "seo" */'@/views/Settings/Seo.vue')
const Layouts = () => import(/* webpackChunkName: "layouts" */'@/views/Settings/Layouts.vue');
// import Layouts from '../views/Settings/Layouts.vue';
// Users
const Users = () => import(/* webpackChunkName: "users" */'@/views/Settings/Users/Users.vue')
const AddUser = () => import(/* webpackChunkName: "addUser" */'@/views/Settings/Users/AddUser.vue')
const EditUser = () => import(/* webpackChunkName: "editUser" */'@/views/Settings/Users/EditUser.vue')
const Graphics = () => import(/* webpackChunkName: "graphics" */'@/views/Settings/Graphics.vue')
const Rebroadcast = () => import(/* webpackChunkName: "rebro" */'@/views/Settings/Rebroadcast.vue')
const PlayerSettings = () => import(/* webpackChunkName: "playerSettings" */'@/views/Settings/PlayerSettings.vue')
const Organizations = () => import(/* webpackChunkName: "organizations" */'@/views/Settings/Organizations/Organizations.vue')
const EditOrganization = () => import(/* webpackChunkName: "editOrganization" */'@/views/Settings/Organizations/EditOrganization.vue')

// Media
const Media = () => import(/* webpackChunkName: "media" */'@/views/Media/Media.vue')
const AddMedia = () => import(/* webpackChunkName: "addMedia" */'@/views/Media/AddMedia.vue')
import EditMedia from '../views/EditMedia.vue'

// Media Categories
const MediaCategories = () => import(/* webpackChunkName: "mediaCat" */'@/views/MediaCategories/MediaCategories.vue')
const AddMediaCategory = () => import(/* webpackChunkName: "addMediaCat" */'@/views/MediaCategories/AddMediaCategory.vue')
const EditMediaCategory = () => import(/* webpackChunkName: "editMediaCat" */'@/views/MediaCategories/EditMediaCategory.vue')
const MediasOrder = () => import(/* webpackChunkName: "mediasOrder" */'@/views/MediaCategories/MediasOrder.vue')

// Not Found
// import NotFound from '../views/NotFound.vue';
const NotFound = () => import(/* webpackChunkName: "404" */'@/views/NotFound.vue')

//Layouts
// const VideoStream = () => import('@/views/Layouts/VideoStream.vue');
// const LayoutsPage = () => import('@/views/Layouts/LayoutsPage.vue');
const Live = () => import('@/views/Layouts/Live.vue');
const OnDemand = () => import('@/views/Layouts/OnDemand.vue');
const HomeLayout = () => import('@/views/Layouts/HomeLayout.vue');
const FeaturedLayout = () => import('@/views/Layouts/FeaturedLayout.vue');
const MediaPlayer = () => import('@/views/Layouts/MediaPlayer.vue');
const Categories = () => import('@/views/Layouts/Categories.vue');

const FormBuilder = () => import(/* webpackChunkName: "formBuilder" */ '@/views/FormBuilder/FormBuilder.vue');
const EditFormBuilder = () => import(/* webpackChunkName: "editFormBuilder" */ '@/views/FormBuilder/SuperEditFormBuilder.vue')

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/admin',
      redirect: '/login',
    },
    {
      path: '/:organizationName',
      name: '.organizationName',
      component: HomeLayout,
      meta: {
        isStream: true,
      }
    },
    {
      path: '/embed/:organizationName',
      name: '.organizationName-embed',
      component: HomeLayout,
      meta: {
        isStream: true,
      }
    },
    {
      path: '/:organizationName/featured',
      name: '.featured',
      component: FeaturedLayout,
      meta: {
        isStream: true,
      }
    },
    {
      path: '/embed/:organizationName/featured',
      name: '.featured-embed',
      component: FeaturedLayout,
      meta: {
        isStream: true,
      }
    },
    {
      path: '/:organizationName/live/:eventId?',
      name: '.live',
      component: Live,
      meta: {
        isStream: true,
      }
    },
    {
      path: '/embed/:organizationName/live/:eventId?',
      name: '.live-embed',
      component: Live,
      meta: {
        isStream: true,
      }
    },
    {
      path: '/:organizationName/videos',
      name: '.videos',
      component: OnDemand,
      meta: {
        isStream: true,
      }
    },
    {
      path: '/embed/:organizationName/videos',
      name: '.videos-embed',
      component: OnDemand,
      meta: {
        isStream: true,
      }
    },
    {
      path: '/:organizationName/videos/:mediaSlug',
      name: '.mediaPlayer',
      component: MediaPlayer,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/embed/:organizationName/videos/:mediaSlug',
      name: '.mediaPlayer-embed',
      component: MediaPlayer,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/:organizationName/videos/:categoryOne/:mediaSlug',
      name: '.mediaPlayer1',
      component: MediaPlayer,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/embed/:organizationName/videos/:categoryOne/:mediaSlug',
      name: '.mediaPlayer1-embed',
      component: MediaPlayer,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/:organizationName/videos/:categoryOne/:categoryTwo/:mediaSlug',
      name: '.mediaPlayer2',
      component: MediaPlayer,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/embed/:organizationName/videos/:categoryOne/:categoryTwo/:mediaSlug',
      name: '.mediaPlayer2-embed',
      component: MediaPlayer,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/:organizationName/videos/:categoryOne/:categoryTwo/:categoryThree/:mediaSlug',
      name: '.mediaPlayer3',
      component: MediaPlayer,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/embed/:organizationName/videos/:categoryOne/:categoryTwo/:categoryThree/:mediaSlug',
      name: '.mediaPlayer3-embed',
      component: MediaPlayer,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/:organizationName/videos/:categoryOne/:categoryTwo/:categoryThree/:categoryFour/:mediaSlug',
      name: '.mediaPlayer4',
      component: MediaPlayer,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/embed/:organizationName/videos/:categoryOne/:categoryTwo/:categoryThree/:categoryFour/:mediaSlug',
      name: '.mediaPlayer4-embed',
      component: MediaPlayer,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/:organizationName/:categoryOne',
      name: '.categoriesOne',
      component: Categories,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/embed/:organizationName/:categoryOne',
      name: '.categoriesOne-embed',
      component: Categories,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/:organizationName/:categoryOne/:categoryTwo',
      name: '.categoriesTwo',
      component: Categories,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/embed/:organizationName/:categoryOne/:categoryTwo',
      name: '.categoriesTwo-embed',
      component: Categories,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/:organizationName/:categoryOne/:categoryTwo/:categoryThree',
      name: '.categoriesThree',
      component: Categories,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/embed/:organizationName/:categoryOne/:categoryTwo/:categoryThree',
      name: '.categoriesThree-embed',
      component: Categories,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/:organizationName/:categoryOne/:categoryTwo/:categoryThree/:categoryFour',
      name: '.categoriesFour',
      component: Categories,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/embed/:organizationName/:categoryOne/:categoryTwo/:categoryThree/:categoryFour',
      name: '.categoriesFour-embed',
      component: Categories,
      meta: {
        // requiresAuth:true,
        isStream: true,
      }
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { guest: true }
    },
    {
      path: '/:organization/etimer',
      name: 'timer',
      component: Timer,
      meta: {
        requiresAuth: false,
        layout: 'timer-layout',
      }
    },
    {
      path: '/:organization/iframe/live-event',
      name: 'live-event',
      component: LiveEvent,
      meta: {
        requiresAuth: false,
        isIframe: true,
        iframeName: 'LiveEvent'
      }
    },
    // {
    //   path: '/:organization/iframe/on-demand',
    //   name: 'on-demand',
    //   component: OnDemand,
    //   meta: {
    //     requiresAuth: false,
    //     isIframe:true,
    //     iframeName: 'OnDemand'
    //   }
    // },
    {
      path: '/:organization/broadcast',
      name: 'broadcast',
      component: Broadcast,
      meta: {
        requiresAuth: false,
        layout: 'broadcast-layout',
      }
    },
    {
      path: '/forgot-password',
      name: 'forgot.password',
      component: ForgotPassword,
      meta: { guest: true }
    },
    {
      path: '/reset-password',
      name: 'reset.password',
      component: ResetPassword,
      meta: { guest: true }
    },
    {
      path: '/podcasts',
      name: 'podcasts',
      component: Podcasts,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/podcasts/add',
      name: 'podcasts.add',
      component: AddPodcast,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/podcasts/edit/:id',
      name: 'podcasts.edit',
      component: EditPodcast,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/ads/types',
      name: 'ads.types',
      component: AdvertisementTypes,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/ads/types/add',
      name: 'ads.types.add',
      component: AddAdvertisementType,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/ads/types/edit/:id',
      name: 'ads.types.edit',
      component: EditAdvertisementType,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/ads',
      name: 'ads',
      component: Advertisement,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/ads/graphic-ads',
      name: 'ads.graphic',
      component: GraphicAds,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/ads/video-ads',
      name: 'ads.video',
      component: VideoAds,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/ads/add',
      name: 'ads.add',
      component: AddAd,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/ads/edit/:id',
      name: 'ads.edit',
      component: EditAdvertisement,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/ads/impressions/:id',
      name: 'ads.impressions',
      component: AdvertisementStatistics,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/settings/organization-details',
      name: 'settings.organization.details',
      component: OrganizationDetails,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/settings/organization-new',
      name: 'settings.organization.new',
      component: NewOrganization,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/settings/links',
      name: 'settings.links',
      component: Links,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/settings/rebroadcast',
      name: 'settings.rebroadcast',
      component: Rebroadcast,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/settings/player/settings',
      name: 'settings.player.settings',
      component: PlayerSettings,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/settings/users',
      name: 'settings.users',
      component: Users,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/settings/users/add',
      name: 'settings.users.add',
      component: AddUser,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/settings/users/edit/:id',
      name: 'settings.users.edit',
      component: EditUser,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/settings/organizations',
      name: 'settings.organizations',
      component: Organizations,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/settings/organizations/edit/:id',
      name: 'settings.organizations.edit',
      component: EditOrganization,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/settings/graphics',
      name: 'settings.graphics',
      component: Graphics,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/notes',
      name: 'notes',
      component: Notes,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/notes/edit/:id',
      name: 'notes.edit',
      component: EditNotes,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/schedule',
      name: 'schedule',
      component: Schedule,
      meta: {
        requiresAuth: true,
      }
    },
    // {
    //   path: '/notes/edit/:id',
    //   name: 'schedule.edit',
    //   component: EditNotes,
    //   meta: {
    //     requiresAuth: true,
    //   }
    // },
    {
      path: '/settings/seo',
      name: 'settings.seo',
      component: Seo,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/settings/layouts',
      name: 'settings.layouts',
      component: Layouts,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/events',
      name: 'events',
      component: Events,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/form-builder',
      name: 'form.builder',
      component: FormBuilder,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/organizations/:id/form-builder',
      name: 'form.builder.edit',
      component: EditFormBuilder,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/events/add',
      name: 'events.add',
      component: AddEvent,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/events/edit/:id',
      name: 'events.edit',
      component: EditEvent,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/media',
      name: 'media',
      component: Media,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/media/categories',
      name: 'media.categories',
      component: MediaCategories,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/media/order',
      name: 'media.order',
      component: MediasOrder,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/media/categories/add',
      name: 'media.categories.add',
      component: AddMediaCategory,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/media/categories/edit/:id',
      name: 'media.categories.edit',
      component: EditMediaCategory,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/media/add',
      name: 'media.add',
      component: AddMedia,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: '/media/edit/:id',
      name: 'media.edit',
      component: EditMedia,
      meta: {
        requiresAuth: true,
      }
    },



    {
      path: '/:catchAll(.*)',
      component: NotFound
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('bearer_token');
  store.commit('setIsAuthenticated', isAuthenticated);

  const messageObject = store.state.messageAfterRedirect;
  if (messageObject.message && messageObject.type) {
    store.dispatch(`snack/${messageObject.type}`, messageObject.message);
  }
  store.commit('clearMessageAfterRedirect');

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth); // check if route requires authentication
  const guestOnly = to.matched.some(record => record.meta.guest); // check if route is for guests only

  if (requiresAuth && !isAuthenticated) {
    window.location.href = 'https://vlifemedia.com'; // redirect to login if route requires authentication but user is not logged in
  } else if (guestOnly && isAuthenticated) {
    next('/'); // redirect to home if route is for guests only but user is already logged in
  } else {
    next(); // proceed to next route
  }
});

export default router