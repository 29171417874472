<template>
  <svg :width="iconSize" :height="iconSize" viewBox="0 0 19 18.037">
    <g id="Group_544" data-name="Group 544" transform="translate(-581.193 -329.5)">
      <text id="_24_7" data-name="24/7" transform="translate(584.693 342)" font-size="6" font-family="CenturyGothic-Bold, Century Gothic" font-weight="700" :fill="iconColor"><tspan x="0" y="0">24/7</tspan></text>
      <path id="Icon_material-event-note" data-name="Icon material-event-note" d="M19.171,2.662h-.852V.958h-1.7v1.7H7.134V.958h-1.7v1.7H4.579a1.7,1.7,0,0,0-1.7,1.7L2.875,16.292a1.7,1.7,0,0,0,1.7,1.7H19.171a1.709,1.709,0,0,0,1.7-1.7V4.366a1.709,1.709,0,0,0-1.7-1.7Z" transform="translate(578.818 329.042)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-width="1"/>
    </g>
  </svg>
</template>

<script>
  export default {
    props: {
      iconSize:Number,
      iconColor:String,
    }
  }
</script>
