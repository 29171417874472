<template>
    <div :id="playerId" :style="playerStyle"></div>
  </template>
  
  <script>
  export default {
    props: {
      videoFile: {
        type:String,
        default: '',
      },
      liveUrl: {
        type:String,
        default: '',
      },
      ClientId:{
        type: Number,
        default: 48
      },
      autostart: {
        type: Boolean,
        default: false,
      },
      width: Number,
      height: Number,
    },
    data() {
      return {
        playerId: 'myElement',
      };
    },
    computed: {
      playerStyle() {
        return {
          width: this.width + 'px',
          height: this.height + 'px',
          background: '#000',
        };
      },
    },
    mounted() {
      const script = document.createElement('script');
      script.src = 'http://www.vlifetech.com/jwplayer-8.19.0/jwplayer.js';
      script.async = true;
      script.onload = () => {
        this.setupPlayer();
      };
      document.head.appendChild(script);
    },
    methods: {
      setupPlayer() {
        jwplayer.key = 's0TstHBWiOohlZ1QoDnawRChOTDEICuiPbqTGw==';

        const VITE_CDN_BASE_URL =  import.meta.env.VITE_CDN_BASE_URL

        let videoUrl = '';

        if(this.liveUrl) videoUrl = this.liveUrl;
        else{
          videoUrl = VITE_CDN_BASE_URL + '00' + this.ClientId +  '/' + this.videoFile + '.m3u8';
        }
  
        jwplayer(this.playerId).setup({
          file: videoUrl,
          autostart: this.autostart,
          width: this.width,
          height: this.height,
          cast: {},
        });
  
        jwplayer(this.playerId).on('ready', () => {
        });
  
        jwplayer(this.playerId).on('pause', () => {
        });
  
        jwplayer(this.playerId).on('complete', () => {
        });
      },
    },
  };
  </script>
  
  <style scoped>

  </style>
  