<template>
  <svg :width="iconSize" :height="iconSize" viewBox="0 0 11.752 11.752">
    <path id="Path_62" data-name="Path 62" d="M12.814,6.35h2.938V7.526H14.577v7.639a.589.589,0,0,1-.588.588H5.763a.589.589,0,0,1-.588-.588V7.526H4V6.35H6.938V4.588A.589.589,0,0,1,7.526,4h4.7a.589.589,0,0,1,.588.588ZM13.4,7.526H6.35v7.051H13.4Zm-2.694,3.526,1.037,1.04-.829.829-1.04-1.037L8.836,12.92l-.829-.829,1.037-1.04-1.037-1.04.829-.829,1.04,1.037,1.04-1.037.829.829ZM8.113,5.175V6.35h3.526V5.175Z" transform="translate(-4 -4)" :fill="iconColor"/>
  </svg>
</template>

<script>
  export default {
    props: {
      iconSize:Number,
      iconColor:String,
    }
  }
</script>