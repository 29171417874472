<template>
  <svg id="Component_56_1" data-name="Component 56 – 1" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <path id="Path_2721" data-name="Path 2721" d="M28.5,39A4.5,4.5,0,1,1,24,34.5,4.494,4.494,0,0,1,28.5,39Zm0-15A4.5,4.5,0,1,1,24,19.5,4.494,4.494,0,0,1,28.5,24Zm0-15A4.5,4.5,0,1,1,24,4.5,4.494,4.494,0,0,1,28.5,9Z" fill="#fff"/>
    <path id="Path_2722" data-name="Path 2722" d="M0,0H48V48H0Z" fill="none"/>
  </svg>
</template>

<script>
  export default {
    props: {
      iconSize:Number,
      iconColor:String,
    }
  }
</script>