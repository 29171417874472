<template>
  <svg :width="iconSize" :height="iconSize" viewBox="0 0 18.297 15.47">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_14" data-name="Rectangle 14" :width="iconSize" :height="iconSize" transform="translate(0 0)" fill="none"/>
      </clipPath>
    </defs>
    <g id="Group_14" data-name="Group 14" transform="translate(0 0)" clip-path="url(#clip-path)">
      <path id="Path_15" data-name="Path 15" d="M15.377,15.123A8.641,8.641,0,1,0,.5,9.148a8.593,8.593,0,0,0,2.42,5.975" fill="none" :stroke="iconColor" stroke-width="1"/>
      <path id="Path_16" data-name="Path 16" d="M4.838,13.232a5.958,5.958,0,1,1,8.621,0" fill="none" :stroke="iconColor" stroke-width="1"/>
      <path id="Path_17" data-name="Path 17" d="M11.4,11.2a2.99,2.99,0,0,0,.785-2.016,3.041,3.041,0,1,0-6.082,0A2.99,2.99,0,0,0,6.892,11.2" fill="none" :stroke="iconColor" stroke-width="1"/>
    </g>
  </svg>
</template>

<script>
  export default {
    props: {
      iconSize:Number,
      iconColor:String,
    }
  }
</script>
