<template>
  <svg id="Component_8_1" data-name="Component 8 – 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="iconSize" :height="iconSize" viewBox="0 0 22.091 15.795">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_30" data-name="Rectangle 30" :width="iconSize" :height="iconSize" :fill="iconColor"/>
      </clipPath>
    </defs>
    <g id="Group_24" data-name="Group 24" clip-path="url(#clip-path)">
      <path id="Path_2610" data-name="Path 2610" d="M1.97,15.795A1.973,1.973,0,0,1,0,13.825V1.97A1.973,1.973,0,0,1,1.97,0H20.121a1.974,1.974,0,0,1,1.97,1.97V13.823a1.974,1.974,0,0,1-1.97,1.97ZM1.97.708A1.264,1.264,0,0,0,.708,1.97V13.825A1.264,1.264,0,0,0,1.97,15.087l18.151,0a1.265,1.265,0,0,0,1.262-1.262V1.97A1.265,1.265,0,0,0,20.121.708Z" transform="translate(0 0)" :fill="iconColor"/>
      <path id="Path_2611" data-name="Path 2611" d="M4.36,14.537v-.664l2.12-2.014a2.812,2.812,0,0,0,.4-.443,1.137,1.137,0,0,0,.172-.355,1.225,1.225,0,0,0,.042-.309.689.689,0,0,0-.251-.568,1.163,1.163,0,0,0-.74-.2,1.753,1.753,0,0,0-.706.138,1.428,1.428,0,0,0-.545.419l-.694-.533A2.056,2.056,0,0,1,5,9.354a2.919,2.919,0,0,1,1.186-.233,2.525,2.525,0,0,1,1.011.187,1.5,1.5,0,0,1,.664.53,1.424,1.424,0,0,1,.233.816,1.977,1.977,0,0,1-.069.515,1.808,1.808,0,0,1-.259.537,4.028,4.028,0,0,1-.557.633L5.382,14.071,5.176,13.7H8.3v.839Z" transform="translate(-1.802 -3.958)" :fill="iconColor"/>
      <path id="Path_2612" data-name="Path 2612" d="M12.143,13.382V12.7l2.632-3.441h1.06L13.242,12.7l-.5-.153h4.233v.838Zm2.929,1.213V13.382l.031-.838V11.467h.931v3.127Z" transform="translate(-5.269 -4.016)" :fill="iconColor"/>
      <path id="Path_2613" data-name="Path 2613" d="M20.767,14.528l2.517-7.185h.854l-2.517,7.185Z" transform="translate(-9.01 -3.186)" :fill="iconColor"/>
      <path id="Path_2614" data-name="Path 2614" d="M27.7,14.594,29.9,9.652l.259.442H27.132l.473-.481V11h-.923V9.255h4.15v.664l-2.075,4.676Z" transform="translate(-11.576 -4.015)" :fill="iconColor"/>
    </g>
  </svg>
</template>

<script>
  export default {
    props: {
      iconSize: Number,
      iconColor: String
    }
  }
</script>