<script>
import { useStore } from 'vuex';

import Header from '../src/components/Header.vue'
import SideMenu from '../src/components/SideMenu.vue'
import Snackbar from './components/Snackbar.vue';
import Iframe from './views/Iframe.vue';
// import VideoStream from './views/Layouts/VideoStream.vue';
import LayoutsPage from './views/Layouts/LayoutsPage.vue';

export default {
  components: {
    Header,
    SideMenu,
    Snackbar,
    Iframe,
    // VideoStream,
    LayoutsPage,
  },
  setup() {
    const store = useStore();

    return {
      store,
    }
  },
  data() {
    return {
      loadOnAuth: false,
    }
  },
  computed: {
    user() {
      return this.store.state.user;
    },
    isAuthenticated() {
      return this.store.state.isAuthenticated;
    },
    layout() {
      return this.$route.meta.layout;
    },
    isIframe() {
      return this.$route.meta.isIframe;
    },
    iframeName() {
      return this.$route.meta.iframeName;
    },
    isStream() {
      return this.$route.meta.isStream;
    }
  },
  async beforeMount() {
    if (this.isAuthenticated && !this.store.state.user) {
      await this.store.dispatch('fetchUser');
    }
  },
}
</script>

<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{ content }} | Vlife</template>
    </metainfo>
  </div>

    <template v-if="!isStream">
      <template v-if="isIframe">
        <Iframe v-bind:iframeName="iframeName" />
      </template>
      <Snackbar v-if="!layout && !isIframe" />
      <div v-if="user && isAuthenticated && !layout && !isIframe" class="logged-state">
        <Header></Header>
        <div class="page-content">
          <SideMenu></SideMenu>

          <div class="content">
            <RouterView />
          </div>

          <img id="vlife-frame" src="@/assets/media/vlife-frame.png" alt="frame">
        </div>
      </div>
      <template v-else>
        <RouterView v-if="(!isAuthenticated && !user) || layout" />
      </template>
    </template>

    <template v-if="isStream">
      <!-- <VideoStream /> -->
      <LayoutsPage/>
    </template>

</template>

<style scoped>
.logged-state {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  max-height: 100vh;
}

.page-content {
  width: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
  margin-top: -65px;
}

.page-content .content {
  flex: 1;
  /* max-height: calc(100vh - 4rem); */
  /* height: calc(100vh - (var(--header-height) + 65px)); */
  /* height: 100svh; */
  max-height: calc(100vh);
  width: 100%;
  padding: 2rem;
  overflow-y: auto;
  z-index: 1;
  margin-top: 65px;
}

#vlife-frame {
  position: absolute;
  /* width: calc(100% - var(--side-menu-width)); */
  /* height: calc(100vh * 1.2); */
  height: 100svh;
  /* right: 0; */
  right: -90px;
  top: -40px;
  opacity: 0.2;
  pointer-events: none;
}

@media only screen and (max-width: 900px) {
  #vlife-frame {
    /* width: 100%; */
    right: -40px;

  }
}

@media only screen and (max-width: 900px) {
  .page-content .content {
    padding: 1rem;
  }
}
</style>
