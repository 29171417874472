<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="iconSize" :height="iconSize" viewBox="0 0 8.203 6.905">
        <g id="Group_728" data-name="Group 728" transform="translate(0.75 1.061)">
            <path id="Path_2665" data-name="Path 2665" d="M26.392,15.284,24,12.892,26.392,10.5" transform="translate(-20 -10.5)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
            <path id="Path_2666" data-name="Path 2666" d="M26.392,15.284,24,12.892,26.392,10.5" transform="translate(-24 -10.5)" fill="none" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
        </g>
        </svg>


</template>

<script>
  export default {
    props: {
      iconSize:Number,
      iconColor:String,
    }
  }
</script>