<template>
  <svg :width="iconSize" :height="iconSize" viewBox="0 0 19 15">
    <g id="Group_543" data-name="Group 543" transform="translate(-581.693 -292)">
      <g id="Rectangle_15" data-name="Rectangle 15" transform="translate(581.693 292)" fill="none" :stroke="iconColor" stroke-width="1">
        <rect width="19" height="15" rx="2" stroke="none"/>
        <rect x="0.5" y="0.5" width="18" height="14" rx="1.5" fill="none"/>
      </g>
      <line id="Line_12" data-name="Line 12" x2="12" transform="translate(585.193 296.5)" fill="none" :stroke="iconColor" stroke-width="1"/>
      <line id="Line_13" data-name="Line 13" x2="10" transform="translate(585.193 299.5)" fill="none" :stroke="iconColor" stroke-width="1"/>
      <line id="Line_14" data-name="Line 14" x2="8" transform="translate(585.193 302.5)" fill="none" :stroke="iconColor" stroke-width="1"/>
    </g>
  </svg>
</template>
<script>
  export default {
    props: {
      iconSize:Number,
      iconColor:String,
    }
  }
</script>