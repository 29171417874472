<script>
import { useStore } from 'vuex';
import LayoutsHeader from './components/LayoutsHeader.vue';
import MetaArticle from './components/MetaArticle.vue';
import layoutsMixin from '@/mixins/layouts.vue';

import Icon24_7 from '@/components/icons/Icon24_7.vue';
import IconOnDemand from '@/components/icons/IconOnDemand.vue';
import IconHearth from '@/components/icons/IconHearth.vue';
import IconHome from '@/components/icons/IconHome.vue';
import IconLive from '@/components/icons/IconLive.vue';
import IconSearch from '@/components/icons/IconSearch.vue';

export default {
    components: {
        LayoutsHeader,
        MetaArticle,
        Icon24_7,
        IconOnDemand,
        IconHearth,
        IconHome,
        IconLive,
        IconSearch
    },
    mixins: [layoutsMixin],
    setup() {
        const store = useStore();
        return {
            store,
        }
    },
    data() {
        return {
            organizationName: this.$route.params.organizationName,
            organization: null,
            eventId: this.$route.params.eventId,
        }
    },
    computed: {
        allCategories() {
            return this.store.state.onDenandCategories;
        },
        computedBackgroundStyle() {
            if (this.mainBackgroundImage) {
                return {
                    background: `repeat top/100% fixed url(${this.$azureUrl + this.mainBackgroundImage})`
                };
            } else {
                return {
                    background: `linear-gradient(135deg, ${this.mainBackgroundColor} 0%, ${this.secondaryBackgroundColor} 55%)`
                };
            }
        },
        navigationBackground() {
            return {
                background: `linear-gradient(0deg, ${this.mainBackgroundColor} 0%, ${this.secondaryBackgroundColor} 64%)`
            };
        },
        navigationColor() {
            return this.store.state.playerData?.layout_data?.colors?.nav_color || '#9c9c9c';
        },
        mainBackgroundColor() {
            return this.store.state.playerData?.layout_data.colors.background_color;
        },
        secondaryBackgroundColor() {
            return this.store.state.playerData?.layout_data?.colors?.secondary_background_color || null;
        },
        mainBackgroundImage() {
            return this.store.state.playerData?.layout_data.background_image;
        },
        metaTitle() {
            return this.store.state.playerData?.seo_data.meta_title || null;
        },
        googleAnalyticsCode() {
            return this.store.state.playerData?.seo_data?.g_analytics_code || null;
        },
        gtagId() {
            return this.store.state.playerData?.seo_data?.gtag_id || null;
        },
        currentRouteName() {
            return this.$route.name;
        },
        cssVars() {
            return {
                '--nav-color': this.store.state.playerData?.layout_data.colors.nav_color,
                '--text-color': this.store.state.playerData?.layout_data.colors.text_color,
                '--section-heading-color': this.store.state.playerData?.layout_data.colors.section_heading_color,
                '--video-title-color': this.store.state.playerData?.layout_data.colors.video_title_color,
                '--video-date-color': this.store.state.playerData?.layout_data.colors.video_date_color,
                '--slider-title-color': this.store.state.playerData?.layout_data.colors.slider_title_color,
                '--text-font': `${this.store.state.playerData?.layout_data.fonts.text_font}`,
                '--header-font': `${this.store.state.playerData?.layout_data.fonts.header_font}`,
                '--sidebar-color': `${this.store.state.playerData?.layout_data.colors.sidebar_color}`,
                '--background-color': this.store.state.playerData?.layout_data.colors.background_color,
           
            }
        },
    },
    methods: {
        async getPlayerData() {
            let res = await this.getCurrentOrganization(this.organizationName);
            this.organization = res;
            this.store.commit('setPlayerData', res);
            this.store.commit('setCurrentOrganization', res);
            this.setFonts(res.layout_data.fonts)
        },
        setFonts(organizationFonts) {
            //HEADER FONT
            let headerFontLink = document.createElement('link');
            headerFontLink.setAttribute('rel', 'stylesheet');
            let textFontLink = document.createElement('link');
            textFontLink.setAttribute('rel', 'stylesheet');

            if (organizationFonts.header_font == 'Josefin Sans') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
            } else if (organizationFonts.header_font == 'Arimo') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');
            } else if (organizationFonts.header_font == 'Barlow') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
            } else if (organizationFonts.header_font == 'DM Sans') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
            } else if (organizationFonts.header_font == 'Dosis') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap');
            } else if (organizationFonts.header_font == 'Fira Sans') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
            } else if (organizationFonts.header_font == 'Heebo') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&display=swap');
            } else if (organizationFonts.header_font == 'IBM Plex Sans') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
            } else if (organizationFonts.header_font == 'Inconsolata') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');
            } else if (organizationFonts.header_font == 'Inter') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
            } else if (organizationFonts.header_font == 'Kanit') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
            } else if (organizationFonts.header_font == 'Karla') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');
            } else if (organizationFonts.header_font == 'Lato') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
            } else if (organizationFonts.header_font == 'Libre Franklin') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap');
            } else if (organizationFonts.header_font == 'Lora') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
            } else if (organizationFonts.header_font == 'Manrope') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
            } else if (organizationFonts.header_font == 'Merriweather') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
            } else if (organizationFonts.header_font == 'Montserrat') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
            } else if (organizationFonts.header_font == 'Mukta') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
            } else if (organizationFonts.header_font == 'Mulish') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
            } else if (organizationFonts.header_font == 'Nanum Gothic') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');
            } else if (organizationFonts.header_font == 'Noto Sans') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
            } else if (organizationFonts.header_font == 'Noto Sans JP') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');
            } else if (organizationFonts.header_font == 'Noto Sans KR') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');
            } else if (organizationFonts.header_font == 'Noto Sans SC') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100..900&display=swap');
            } else if (organizationFonts.header_font == 'Noto Sans TC') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap');
            } else if (organizationFonts.header_font == 'Noto Serif') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap');
            } else if (organizationFonts.header_font == 'Nunito') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
            } else if (organizationFonts.header_font == 'Nunito Sans') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
            } else if (organizationFonts.header_font == 'Open Sans') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
            } else if (organizationFonts.header_font == 'Oswald') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
            } else if (organizationFonts.header_font == 'Playfair Display') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
            } else if (organizationFonts.header_font == 'Poppins') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
            } else if (organizationFonts.header_font == 'PT Sans') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            } else if (organizationFonts.header_font == 'PT Serif') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            } else if (organizationFonts.header_font == 'Quicksand') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
            } else if (organizationFonts.header_font == 'Raleway') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
            } else if (organizationFonts.header_font == 'Roboto') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
            } else if (organizationFonts.header_font == 'Roboto Condensed') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
            } else if (organizationFonts.header_font == 'Roboto Mono') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
            } else if (organizationFonts.header_font == 'Roboto Slab') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');
            } else if (organizationFonts.header_font == 'Rubik') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
            } else if (organizationFonts.header_font == 'Source Code Pro') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');
            } else if (organizationFonts.header_font == 'Titillium Web') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
            } else if (organizationFonts.header_font == 'Ubuntu') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
            } else if (organizationFonts.header_font == 'Work Sans') {
                headerFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
            }

            //TEXT FONT
            if (organizationFonts.text_font == 'Josefin Sans') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
            } else if (organizationFonts.text_font == 'Arimo') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');
            } else if (organizationFonts.text_font == 'Barlow') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
            } else if (organizationFonts.text_font == 'DM Sans') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
            } else if (organizationFonts.text_font == 'Dosis') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap');
            } else if (organizationFonts.text_font == 'Fira Sans') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
            } else if (organizationFonts.text_font == 'Heebo') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&display=swap');
            } else if (organizationFonts.text_font == 'IBM Plex Sans') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
            } else if (organizationFonts.text_font == 'Inconsolata') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');
            } else if (organizationFonts.text_font == 'Inter') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
            } else if (organizationFonts.text_font == 'Kanit') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
            } else if (organizationFonts.text_font == 'Karla') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap');
            } else if (organizationFonts.text_font == 'Lato') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
            } else if (organizationFonts.text_font == 'Libre Franklin') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap');
            } else if (organizationFonts.text_font == 'Lora') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
            } else if (organizationFonts.text_font == 'Manrope') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
            } else if (organizationFonts.text_font == 'Merriweather') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
            } else if (organizationFonts.text_font == 'Montserrat') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
            } else if (organizationFonts.text_font == 'Mukta') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
            } else if (organizationFonts.text_font == 'Mulish') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');
            } else if (organizationFonts.text_font == 'Nanum Gothic') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');
            } else if (organizationFonts.text_font == 'Noto Sans') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
            } else if (organizationFonts.text_font == 'Noto Sans JP') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');
            } else if (organizationFonts.text_font == 'Noto Sans KR') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');
            } else if (organizationFonts.text_font == 'Noto Sans SC') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100..900&display=swap');
            } else if (organizationFonts.text_font == 'Noto Sans TC') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap');
            } else if (organizationFonts.text_font == 'Noto Serif') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap');
            } else if (organizationFonts.text_font == 'Nunito') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
            } else if (organizationFonts.text_font == 'Nunito Sans') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
            } else if (organizationFonts.text_font == 'Open Sans') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
            } else if (organizationFonts.text_font == 'Oswald') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
            } else if (organizationFonts.text_font == 'Playfair Display') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
            } else if (organizationFonts.text_font == 'Poppins') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
            } else if (organizationFonts.text_font == 'PT Sans') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            } else if (organizationFonts.text_font == 'PT Serif') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            } else if (organizationFonts.text_font == 'Quicksand') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
            } else if (organizationFonts.text_font == 'Raleway') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
            } else if (organizationFonts.text_font == 'Roboto') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
            } else if (organizationFonts.text_font == 'Roboto Condensed') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
            } else if (organizationFonts.text_font == 'Roboto Mono') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
            } else if (organizationFonts.text_font == 'Roboto Slab') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');
            } else if (organizationFonts.text_font == 'Rubik') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
            } else if (organizationFonts.text_font == 'Source Code Pro') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');
            } else if (organizationFonts.text_font == 'Titillium Web') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
            } else if (organizationFonts.text_font == 'Ubuntu') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
            } else if (organizationFonts.text_font == 'Work Sans') {
                textFontLink.setAttribute('href', 'https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
            }

            document.head.appendChild(headerFontLink);
            document.head.appendChild(textFontLink);
        },
        setGoogleScript() {
            // debugger;
            // 3rd works !
            // if (script.includes('gtag')) {
                // TO REMOVE G-8FDMF0VN3W WHEN Mitko add it to bg
                // let gtag = script.split('https://www.googletagmanager.com/gtag/js?id=')[1].split('">').shift();
                // this.activateGtm(window, document, 'script', 'dataLayer', this.gtagId);
                // console.log(this.gtagId);
                // document.head.insertAdjacentHTML("afterbegin", script);
            // }else{
                // console.log(this.gtagId);
                this.activateGtm(window, document, 'script', 'dataLayer', this.gtagId);
                // document.head.insertAdjacentHTML("afterbegin", script);
            // }
            

        },
        activateGtm(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        },
        async getSlidersCategories() {
            try {
                const data = {
                    by_date: this.byDate,
                    page: 1,
                    in_slider: true,
                }

                this.VODCategories = await this.firstLoad(this.organizationName, data);
                this.store.commit('setOnDenandCategories', this.VODCategories);
            } catch (error) {
                throw error;
            }
        },
    },
    watch: {
        googleAnalyticsCode() {
            if (this.googleAnalyticsCode) {
                this.setGoogleScript()
            }
        }
    },
    beforeMount() {
        this.setGoogleScript()
    },
    mounted() {
        this.getPlayerData();
        this.getSlidersCategories();
    }
}
</script>

<template>
    <div class="wrapper"
        :style="computedBackgroundStyle"
    >
        <div class="layout-wrapper" :style="cssVars">
            <!-- Header -->
            <LayoutsHeader 
                v-if="organizationName && !currentRouteName.includes('embed')"
                :organization="organization"
                :organizationName="this.organizationName" 
                :eventId="this.eventId" 
            />

            <!-- Content -->
            <main>
                <RouterView />
                <div v-if="metaTitle">
                    <MetaArticle />
                </div>
            </main>

            <!-- Bottom Navigation -->
            <div 
                v-if="organizationName && !currentRouteName.includes('embed')" 
                class="bottom-menu"
                :style="navigationBackground"
            >
                <router-link :to="{ name: '.organizationName', params: { organizationName: organizationName } }" v-slot="{ isActive }">
                    <IconHome :iconColor="isActive ? navigationColor : '#9c9c9c'" :iconSize="isActive ? 26 : 22"/>
                </router-link>
                <router-link :to="{ name: '.live', params: { organizationName: organizationName, eventId: eventId } }" v-slot="{ isActive }">
                    <IconLive :iconColor="isActive ? navigationColor : '#9c9c9c'" :iconSize="isActive ? 26 : 22"/>
                </router-link>
                <router-link :to="{ name: '.organizationName', params: { organizationName: organizationName } }" v-slot="{ isActive }">
                    <Icon24_7 :iconColor="isActive ? navigationColor : '#9c9c9c'" :iconSize="isActive ? 26 : 22"/>
                </router-link>
                <router-link v-if="allCategories && allCategories.length && allCategories.some(category => category.media_count || category.has_subcategories)" :to="{ name: '.videos', params: { organizationName: organizationName } }" v-slot="{ isActive }">
                    <IconOnDemand :iconColor="isActive && $route.query.search === undefined ? navigationColor : '#9c9c9c'" :iconSize="isActive ? 26 : 22"/>
                </router-link>
                <a v-if="organization?.url_data?.donation" :href="organization.url_data.donation" target="_blank">
                    <IconHearth :iconColor="'#9c9c9c'" :iconSize="22"/>
                </a>
                <router-link :to="{ name: '.videos', params: { organizationName: organizationName }, query: { search: '' } }" v-slot="{ isActive }">
                    <IconSearch :iconColor="isActive && $route.query.search !== undefined ? navigationColor : '#9c9c9c'" :iconSize="isActive ? 26 : 22"/>
                </router-link>
            </div>
        </div>
    </div>
</template>

<style scoped>
.wrapper {
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-image: no-repeat;
}

.layout-wrapper {
    max-width: 1280px;
    /* padding: 35px 0; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-inline: auto;
}

main {
    width: 100%;
    display: flex;
    justify-content: center;
}

/* #region Bottom Navigation */
.bottom-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 60px;
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 0px 17px;
}
/* #endregion Bottom Navigation */

@media screen and (max-width: 600px) {
    .bottom-menu {
        display: flex;
    }
}
</style>