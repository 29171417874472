<template>
  <component :is="iconName" :iconColor="iconColor" :iconSize="iconSize"/>
</template>

<script>
import IconPodcast from './icons/IconPodcast.vue';
import IconMedia from './icons/IconMedia.vue';
import IconSettings from './icons/IconSettings.vue';
import IconSermonNotes from './icons/IconSermonNotes.vue';
import IconAds from './icons/IconAds.vue';
import IconEvents from './icons/IconEvents.vue';
import IconSchedule from './icons/IconSchedule.vue';
import IconReports from './icons/IconReports.vue';
import IconDelete from './icons/IconDelete.vue';
import IconEdit from './icons/IconEdit.vue';
import IconCirclePlus from './icons/IconCirclePlus.vue';
import DoubleArrowsLeft from './icons/DoubleArrowsLeft.vue';
import DoubleArrowsRight from './icons/DoubleArrowsRight.vue';
import XTwitter from './icons/XTwitter.vue';

export default {
  components: {
    IconPodcast,
    IconMedia,
    IconSettings,
    IconSermonNotes,
    IconAds,
    IconEvents,
    IconSchedule,
    IconReports,
    IconDelete,
    IconEdit,
    IconCirclePlus,
    DoubleArrowsLeft,
    DoubleArrowsRight,
    XTwitter,
  },
  props: {
    iconName: {
      type: String,
      required: true,
    },
    iconColor: {
      type: String,
      default: '#949494',
    },
    iconSize: {
      type:  Number ,
      default: 25,
    },
  },
};
</script>
