
<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useMeta } from 'vue-meta';

export default {
    setup() {
        const store = useStore();
        const metaTitle = computed(() => store.state.playerData?.seo_data.meta_title ?? 'Default Title');
        const metaDescription = computed(() => store.state.playerData?.seo_data.meta_desc ?? 'Default desc');
        const metaKey = computed(() => store.state.playerData?.seo_data.meta_key ?? 'Default key');
        useMeta({
            title: `${metaTitle.value}`,
            description: `${metaDescription.value}`,
            key: `${metaKey.value}`,
            // htmlAttrs: {
            //     lang: 'en',
            //     amp: true
            // }
        })
        return {
            store,
            metaTitle,
            metaDescription,
            metaKey,
            useMeta,
        }
    },
}
</script>

<template>
</template>

<style scoped></style>