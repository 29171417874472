<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="iconSize" :height="iconSize" viewBox="0 0 18.367 18.367">
    <path id="Path_2725" data-name="Path 2725" d="M11.348,4a7.348,7.348,0,1,0,4.5,13.152L20.8,22.1a.919.919,0,0,0,1.3-1.3l-4.946-4.946A7.348,7.348,0,0,0,11.348,4ZM5.837,11.348a5.511,5.511,0,1,1,5.511,5.511A5.509,5.509,0,0,1,5.837,11.348Z" transform="translate(-4 -4)" :fill="iconColor"/>
  </svg>
</template>

<script>
  export default {
    props: {
      iconSize:Number,
      iconColor:String,
    }
  }
</script>