import axios from 'axios';

const instance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  // withCredentials: true,
});

instance.interceptors.request.use(
  async (config) => {
    const bearerToken = localStorage.getItem('bearer_token');
    if (bearerToken) {
      config.headers['Authorization'] = `Bearer ${bearerToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.create = function (config) {
  // Create a new Axios instance with the provided config
  const newAxiosInstance = axios.create(config);
  return newAxiosInstance;
};

export default instance;
