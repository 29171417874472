<template>
  <svg :width="iconSize" :height="iconSize" viewBox="0 0 21.49 15.362">
    <g id="Group_10" data-name="Group 10" transform="translate(-22.5 -401.498)">
      <path id="Path_10" data-name="Path 10" d="M14.038,14.5H2.5a2,2,0,0,1-2-2V2.5a2,2,0,0,1,2-2h14a2,2,0,0,1,2,2v5" transform="translate(22.5 401.498)" fill="none" :stroke="iconColor" stroke-width="1"/>
      <rect id="Rectangle_11" data-name="Rectangle 11" width="12" height="4" transform="translate(26 404.998)" :fill="iconColor"/>
      <line id="Line_8" data-name="Line 8" y2="5.979" transform="translate(41 410.881)" fill="none" :stroke="iconColor" stroke-width="1"/>
      <line id="Line_9" data-name="Line 9" x1="5.979" transform="translate(38.011 413.87)" fill="none" :stroke="iconColor" stroke-width="1"/>
    </g>
  </svg>
</template>

<script>
  export default {
    props: {
      iconSize:Number,
      iconColor:String,
    }
  }
</script>