<template>
    <svg :width="iconSize" :height="iconSize"  viewBox="0 0 27 27">
    <g id="Group_757" data-name="Group 757" transform="translate(-0.5 -0.5)">
        <g id="Ellipse_4" data-name="Ellipse 4" transform="translate(0.5 0.5)" fill="#fff" :stroke="iconColor" stroke-width="0.5">
        <circle cx="13.5" cy="13.5" r="13.5" stroke="none"/>
        <circle cx="13.5" cy="13.5" r="13.25" fill="none"/>
        </g>
        <path id="Path_99" data-name="Path 99" d="M-2.813-16.808v-6.767H-.677v6.767H6.137v2.136H-.677v6.767H-2.813v-6.767H-9.626v-2.136Z" transform="translate(16.126 30.075)" :fill="iconColor"/>
    </g>
    </svg>
</template>

<script>
  export default {
    props: {
      iconSize:Number,
      iconColor:String,
    }
  }
</script>