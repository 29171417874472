export default {
  namespaced: true,

  state: {
    message: '',
    type: '',
  },

  getters: {
    message (state) {
      return state.message
    },
    type (state) {
      return state.type
    }
  },

  mutations: {
    setMessage(state, message) {
      state.message = message;
    },
    setType(state, type) {
      state.type = type;
    },
    clearSnackbar(state) {
      state.message = '';
      state.type = '';
    },
  },

  actions: {
    success({ commit }, message) {
      commit('setMessage', message);
      commit('setType', 'success');

      setTimeout(() => {
        commit('clearSnackbar');
      }, 3000);
    },
    error({ commit }, message) {
      commit('setMessage', message);
      commit('setType', 'error');

      setTimeout(() => {
        commit('clearSnackbar');
      }, 3000);
    },
    warning({ commit }, message) {
      commit('setMessage', message);
      commit('setType', 'warning');

      setTimeout(() => {
        commit('clearSnackbar');
      }, 3000);
    },
    show({ commit }, { message, type }) {
      if (
        (message !== null && message !== '' && message !== undefined) &&
        (type !== null && type !== '' && type !== undefined)
      ) {
        commit('setMessage', message);
        commit('setType', type);
  
        setTimeout(() => {
          commit('clearSnackbar');
        }, 3000);
      }
    },
  },
}
  