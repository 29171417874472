
<template>
  <svg :width="iconSize" :height="iconSize" viewBox="0 0 10.098 12.465">
  <g id="Group_86" data-name="Group 86" transform="translate(0.5 0.5)">
      <path id="Path_68" data-name="Path 68" d="M24.346,31H18a1,1,0,0,1,0-2h6.346a1,1,0,1,1,0,2Z" transform="translate(-15.748 -19.035)" :fill="iconColor"/>
      <path id="Icon_material-edit" data-name="Icon material-edit" d="M6.5,13.594H4V11.1L9.288,5.809l2.5,2.5Zm-1.5-1H6.084l4.288-4.287L9.288,7.223,5,11.51Zm7.084-4.586-2.5-2.5,1.228-1.228A.97.97,0,0,1,11.5,4h0a.97.97,0,0,1,.69.286L13.312,5.4a.976.976,0,0,1,0,1.381ZM11,5.51l1.084,1.084.5-.5L11.5,5.006Z" transform="translate(-4.5 -4.496)" :fill="iconColor"/>
  </g>
  </svg>
</template>

<script>
  export default {
    props: {
      iconSize:Number,
      iconColor:String,
    }
  }
</script>